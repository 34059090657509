@tailwind base;
@tailwind components;
@tailwind utilities;

@import "code.css";

.prose * {
  @apply text-gray-700 dark:text-gray-200;
}

.github:hover {
  color: #131111;
}
.twitter:hover {
  color: #1d87ec;
}

.linkedin:hover {
  color: #0f4fb6;
}

.prose code {
  @apply rounded dark:bg-gray-700 text-gray-800 dark:text-gray-200;
}
